import camelCase from "lodash/camelCase";

export const scroll = ({ container, direction, to, duration }) => {
  let starting;

  if (container === window) {
    if (direction === `y`) {
      starting = parseInt(container.scrollY);
    } else {
      starting = parseInt(container.scrollX);
    }
  } else {
    if (direction === `y`) {
      starting = parseInt(container.scrollTop);
    } else {
      starting = parseInt(container.scrollLeft);
    }
  }

  let animationFrameId;
  let startingTimestamp;
  let position = starting;
  const step = (timestamp) => {
    if (!startingTimestamp) {
      startingTimestamp = timestamp;
    }

    const progress = timestamp - startingTimestamp;

    if (progress <= duration) {
      const percent = (progress / duration) * 100;
      position = position + (((to - position) / 100) * percent);

      if (container === window) {
        if (direction === `y`) {
          window.scrollTo(window.scrollX, position);
        } else {
          window.scrollTo(position, window.scrollY);
        }
      } else {
        if (direction === `y`) {
          container.scrollTop = position;
        } else {
          container.scrollLeft = position;
        }
      }

      return (animationFrameId = window.requestAnimationFrame(step));
    }
    
    window.cancelAnimationFrame(animationFrameId);
  };

  animationFrameId = window.requestAnimationFrame(step);
};

export const formatContent = (content) => {
  return content
    .replace(/\[\[form-start:(.*?)\]\]/g, (_, $1) => `<fieldset><legend>${$1}</legend><form id="${$1}" class="wysiwyg-form">`)
    .replace(/\[\[text:(.*?)\]\]/g, (_, $1) => `<span class="form-floating"><input type="text" class="form-control" id="${camelCase($1)}" name="${$1}" placeholder="${$1}"><label for="${camelCase($1)}">${$1}</label></span>`)
    .replace(/\[\[checkbox:(.*?)\]\]/g, (_, $1) => `<span class="form-check"><input class="form-check-input" type="checkbox" value="Yes" id="${camelCase($1)}" name="${$1}"><label class="form-check-label" for="${camelCase($1)}">${$1}</label></span>`)
    .replace(/\[\[yes-no:(.*?)\]\]/g, (_, $1) => `<span><label class="me-2">${$1}</label><span class="form-check form-check-inline"><input class="form-check-input" type="radio" id="${camelCase($1)}_yes" value="Yes" name="${$1}" checked><label class="form-check-label" for="${camelCase($1)}_yes">Yes</label></span><span class="form-check form-check-inline"><input class="form-check-input" type="radio" id="${camelCase($1)}_no" value="No" name="${$1}"><label class="form-check-label" for="${camelCase($1)}_no">No</label></span></span>`)
    .replace(/\[\[select:(.*?):(.*?)\]\]/g, (_, $1, $2) => `<span class="form-floating"><select class="form-select" id="${camelCase($1)}" aria-label="${$1}" name="${$1}">${$2.split(`,`).map((value) => `<option>${value}</option>`)}</select><label for="${camelCase($1)}">${$1}</label></span>`)
    .replace(/\[\[form-end.*?\]\]/g, `<button type="submit" class="btn btn-success">Submit</button></fieldset></form>`);
};