import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: auto !important;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }
`;

export const StyledApp = styled.div``;