import { StyledLoading, StyledSpan, StyledWrapper } from "./loading.style";

export const Loading = () => {
  return (
    <StyledWrapper>
      <StyledLoading>
        <StyledSpan />
        <StyledSpan />
      </StyledLoading>
    </StyledWrapper>
  );
};
