import { useRef } from "react";

const preloadImage = key => new Promise((resolve, reject) => {
  const image = new Image();

  image.onload = () => resolve(key);
  image.onerror = () => reject();
  image.src = `${window.location.protocol}//${window.location.host}/api/media/${key}`;
});

export const MediaUpload = ({ onUploaded, setUploading, inputRef }) => {
  const formNode = useRef();
  const handleChange = async (event) => {
    setUploading(true);

    try {
      const file = event.target.files[0];
      const response = await fetch(`/api/media/${file.name}`, {
        method: 'PUT',
        body: file,
      });

      if (response.status !== 200) {
        throw new Error();
      }

      const { key } = await response.json();
      
      await preloadImage(key);
      
      if (key) {
        setUploading(false);
        onUploaded(key);
        formNode.current.reset();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      setUploading(false);
      onUploaded();
      formNode.current.reset();
    }
  };

  return (
    <form ref={formNode}>
      <input type="file" accept="image/*" onChange={handleChange} ref={inputRef} style={{ display: `none` }} />
    </form>
  );
};