import styled, { keyframes } from "styled-components";

const borderWidth = 0.25;
const finalWidth = 4;


const loading = keyframes`
  0% {
    top: ${finalWidth / 2 - borderWidth}rem;
    left:  ${finalWidth / 2 - borderWidth}rem;
    height: ${borderWidth * 2}rem;
    width: ${borderWidth * 2}rem;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    height: ${finalWidth}rem;
    width: ${finalWidth}rem;
    opacity: 0;
  }
`;

export const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1301;
`;

export const StyledLoading = styled.div`
  display: inline-block;
  height: ${finalWidth}rem;
  left: 50%;
  margin: -${finalWidth / 2}rem 0 0 -${finalWidth / 2}rem;
  position: absolute;
  top: 50%;
  width: ${finalWidth}rem;
`;

export const StyledSpan = styled.span`
  position: absolute;
  border: ${borderWidth}rem solid #707070;
  border-radius: 50%;
  animation: ${loading} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  &:nth-child(2) {
    animation-delay: -0.5s;
  }
`;
