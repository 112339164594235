import { useCallback, useEffect, useState } from "react";
import { format, formatDistance } from "date-fns";

import { ChooseDomain, EditMetadata } from "../";

export const Admin = ({ settings, editing, handleAddSectionClick, pageSettings, setIsLoading }) => {
  const [submissions, setSubmissions] = useState([]);
  const fetchData = useCallback(async () => {
    const submissionsResponse = await fetch(`/api/submissions/${pageSettings.pageId}`);
    const submissions = await submissionsResponse.json();

    setSubmissions(submissions);
  }, [pageSettings.pageId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="my-4"><button className="btn btn-outline-primary btn-sm" disabled={editing} onClick={handleAddSectionClick}>Add new section</button></div>
      {!pageSettings.domain && (
        <ChooseDomain setIsLoading={setIsLoading} pageSettings={pageSettings} />
      )}
      <div className="my-4">
        <EditMetadata setIsLoading={setIsLoading} pageSettings={pageSettings} />
      </div>
      <div className="my-4"><a href={`${window.location.protocol}//${pageSettings.domain ? pageSettings.domain : `${window.location.host}${window.location.pathname}`}?mode=preview`} target="_blank" rel="noreferrer">View page as a user</a></div>
      {settings.qrCodeUrl && (
        <div className="my-4">
          <img src={settings.qrCodeUrl} alt={`QR code for ${settings.qrCodeUrl}`} width="208" />
          <div className="mt-2"><a className="btn btn-outline-primary btn-sm" href={settings.qrCodeUrl} download>Download QR code</a></div>
        </div>
      )}
      <div className="my-4">
        <h4>Need help?</h4>
        <p>Email us: <a href="mailto:support@onepagecomposer.com">support@onepagecomposer.com</a></p>
      </div>
      <div className="my-4"><a className="btn btn-outline-warning btn-sm" href="/logout">Logout</a></div>
      {Boolean(submissions.length) && (
        <div className="my-4">
          <h3>Submissions</h3>
          {submissions.map(({ key, label, items }) => (
            <div key={key} className="my-3">
              <h4>{label}</h4>
              <div className="bg-white" style={{ overflow: `auto`, maxWidth: `fit-content` }}>
                {items.map(({ data, createdAt, id }, index) => (
                  <div key={id} className={`d-flex p-2 ${index % 2 === 0 ? ` bg-light` : ``}`} style={{ width: `fit-content` }}>
                    <div className="me-2 text-nowrap d-flex">
                      <b className="me-1">Submitted:</b>
                      <span title={format(createdAt, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)}>{formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</span>
                    </div>
                    {data.map(({ key: subKey, label: subLabel, value: subValue }) => (
                      <div key={`${id}_${subKey}`} className="me-2 text-nowrap d-flex">
                        <b className="me-1">{subLabel}:</b>
                        <span>{subValue || `Not provided`}</span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}