import { useState } from "react";

export const EditMetadata = ({ pageSettings, setIsLoading }) => {
  const [details, setDetails] = useState({
    name: pageSettings.name,
    description: pageSettings.description,
  });
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const response = await fetch(`/api/metadata/${pageSettings.pageId}`, { method: `POST`, body: JSON.stringify(details) });

    if (response.status !== 200) {
      window.alert(`Unable to edit metadata, please try again.`);
    } else {
      window.alert(`Metadata updated.`);
      window.location.reload();
    }

    setIsLoading(false);
  };

  return (
    <>
      <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#editMetadataModal">
        Edit metadata
      </button>
      <div className="modal fade" id="editMetadataModal" tabIndex="-1" aria-labelledby="editMetadataModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editMetadataModalLabel">Edit metadata</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-floating my-2">
                  <input type="text" className="form-control" id="name" placeholder="Page 1" required value={details.name} onChange={(event) => setDetails({ ...details, name: event.target.value })} />
                  <label htmlFor="firstName">Name</label>
                </div>
                <div className="form-floating my-2">
                  <input type="text" className="form-control" id="description" placeholder="A cool new site" required value={details.description} onChange={(event) => setDetails({ ...details, description: event.target.value })} />
                  <label htmlFor="lastName">Description</label>
                </div>
                <div className="d-flex my-2">
                  <button type="submit" className="btn btn-success ms-auto">Submit</button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};