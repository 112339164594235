import { useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const Join = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isCanceled = query.get('canceled');
  const isSuccess = query.get('success');
  const fetchData = useCallback(async () => {
    if (isCanceled || isSuccess) {
      navigate(`/`);
  
      return;
    }

    const sessionResponse = await fetch(`/session`);
    const tmpIsLoggedIn = sessionResponse.status === 200;

    if (tmpIsLoggedIn) {
      const pagesResponse = await fetch(`/api/pages`);

      if (pagesResponse.status === 200) {
        navigate(`/`);

        return;
      }
    }
  
    const checkoutSessionResponse = await fetch('/api/pay/create-checkout-session', { method: 'POST' });
    const { url } = await checkoutSessionResponse.json();
  
    window.location.href = url;
  }, [isCanceled, isSuccess, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return null;
};