import { useRef, useState } from "react";

export const useEphemralState = (startsAs, lastsFor) => {
  const [state, setState] = useState(startsAs);
  const timeout = useRef();
  const handleChange = (changesTo) => {
    setState(changesTo);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setState(startsAs), lastsFor);
  };

  return [state, handleChange];
};