import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalStyle, StyledApp } from "./app.styles";
import { Main } from "./main";
import { Login } from "./login";
import { Join } from "./join";

const AppRoutes = () => {
  return (
    <StyledApp>
      <Routes>
        <Route path="/" element={<Main pageSettings={window.PAGE_SETTINGS} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/p/:pageId" element={<Main pageSettings={window.PAGE_SETTINGS} />} />
        <Route path="/join" element={<Join />} />
      </Routes>
    </StyledApp>
  );
};

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}
