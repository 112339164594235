import { useRef, useState } from "react";

import countryCodes from "./country-codes.json";

export const ChooseDomain = ({ setIsLoading, pageSettings }) => {
  const [domain, setDomain] = useState(``);
  const [tld, setTld] = useState(`.co.uk`);
  const [available, setAvailable] = useState();
  const [isLockedIn, setIsLockedIn] = useState();
  const [details, setDetails] = useState({
    firstName: ``,
    lastName: ``,
    phoneCountryCode: `+44`,
    phone: ``,
    country: `United Kingdom`,
    addressLine1: ``,
    city: ``,
    county: ``,
    postcode: ``,
  });
  const domainInputNode = useRef();
  const openModal = () => {
    setAvailable();
    setTimeout(() => {
      domainInputNode.current.focus();
    }, 500);
  };
  const handleDomainChange = (event) => {
    setAvailable();
    setDomain(event.target.value.trim().toLowerCase());
  };
  const handleTldChange = (tld) => {
    setAvailable();
    setTld(tld);
  };
  const handleAvailabilitySubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const response = await fetch(`/api/domain/${domain}${tld}`);
    const json = await response.json();

    setIsLoading(false);
    setAvailable(Boolean(json?.available));
  };
  const handleLockIn = () => {
    if (!window.confirm(`Are you sure you want to choose ${domain}${tld}. This can't be changed later.`)) return;
    setIsLockedIn(true);
  };
  const handleDetailsSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const response = await fetch(`/api/domain/${domain}${tld}`, { method: `POST`, body: JSON.stringify({ ...details, pageId: pageSettings.pageId }) });

    if (response.status !== 200) {
      window.alert(`Unable to choose domain, please try again.`);
    } else {
      window.alert(`Domain chosen. ${domain}${tld} will become active shortly.`);
      window.location.reload();
    }

    setIsLoading(false);
  };

  return (
    <>
      <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#chooseDomainModal" onClick={openModal}>
        Choose domain
      </button>
      <div className="modal fade" id="chooseDomainModal" tabIndex="-1" aria-labelledby="chooseDomainModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="chooseDomainModalLabel">Choose domain</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{ minHeight: `20rem` }}>
              <form onSubmit={handleAvailabilitySubmit}>
                <div className="input-group my-2">
                  <input type="text" className="form-control" ref={domainInputNode} onChange={handleDomainChange} value={domain} disabled={isLockedIn} required pattern="^[0-9a-z]+$" />
                  <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={isLockedIn}>{tld}</button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><span className="dropdown-item" onClick={() => handleTldChange(`.co.uk`)}>.co.uk</span></li>
                    <li><span className="dropdown-item" onClick={() => handleTldChange(`.com`)}>.com</span></li>
                  </ul>
                  <small id="passwordHelpBlock" class="form-text text-muted">
                    Can only include letters & numbers without spaces
                  </small>
                </div>
                {!isLockedIn && (
                  <div className="d-flex my-2">
                    {available !== undefined && <div>{available ? <span className="text-success"><i className="bi bi-check"></i>Available</span> : <span className="text-danger"><i className="bi bi-x"></i>Unavailable</span>}</div>}
                    <button type="submit" className="btn btn-sm btn-outline-primary ms-auto" disabled={domain === `` || isLockedIn}>Check availability</button>
                  </div>
                )}
              </form>
              {!isLockedIn && available && (
                <div className="d-flex my-2">
                  <button type="button" className="btn btn-sm btn-success mx-auto" onClick={handleLockIn}>Choose <b>{domain}{tld}</b></button>
                </div>
              )}
              {isLockedIn && (
                <>
                  <p className="mt-4"><small>The follwoing details are required due to the regulatory requirements of the domain registrar and will not be used for marketing purposes.</small></p>
                  <form onSubmit={handleDetailsSubmit}>
                    <div className="form-floating my-2">
                      <input type="text" className="form-control" id="firstName" autoComplete="given-name" placeholder="Joe" required value={details.firstName} onChange={(event) => setDetails({ ...details, firstName: event.target.value })} />
                      <label htmlFor="firstName">First name</label>
                    </div>
                    <div className="form-floating my-2">
                      <input type="text" className="form-control" id="lastName" autoComplete="family-name" placeholder="Smith" required value={details.lastName} onChange={(event) => setDetails({ ...details, lastName: event.target.value })} />
                      <label htmlFor="lastName">Last name</label>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="form-floating" style={{ maxWidth: `5rem` }}>
                        <select className="form-select" id="phoneCountryCode" autoComplete="tel-country-code" required value={details.phoneCountryCode} onChange={(event) => setDetails({ ...details, phoneCountryCode: event.target.value })}>
                          {countryCodes.map(({ country, code }) => (
                            <option key={`phoneCountryCode_${country}`} value={`+${code}`}>+{code} - {country}</option>
                          ))}
                        </select>
                        <label htmlFor="phoneCountryCode">Code</label>
                      </div>
                      <div className="form-floating my-2 flex-grow-1 ms-2">
                        <input type="tel" className="form-control" id="phone" autoComplete="tel" placeholder="7745094844" required value={details.phone} onChange={(event) => setDetails({ ...details, phone: event.target.value })} />
                        <label htmlFor="phone">Phone</label>
                      </div>
                    </div>
                    <div className="form-floating">
                      <select className="form-select" id="country" required value={details.country} onChange={(event) => setDetails({ ...details, country: event.target.value })}>
                        {countryCodes.map(({ country }) => (
                          <option key={`country_${country}`}>{country}</option>
                        ))}
                      </select>
                      <label htmlFor="country">Country</label>
                    </div>
                    <div className="form-floating my-2">
                      <input type="text" className="form-control" id="addressLine1" autoComplete="address-line1" placeholder="81 The Street" required value={details.addressLine1} onChange={(event) => setDetails({ ...details, addressLine1: event.target.value })} />
                      <label htmlFor="addressLine1">Address line 1</label>
                    </div>
                    <div className="form-floating my-2">
                      <input type="text" className="form-control" id="city" autoComplete="address-level2" placeholder="Manchester" required value={details.city} onChange={(event) => setDetails({ ...details, city: event.target.value })} />
                      <label htmlFor="city">City</label>
                    </div>
                    <div className="form-floating my-2">
                      <input type="text" className="form-control" id="county" autoComplete="address-level1" placeholder="Cheshire" required value={details.county} onChange={(event) => setDetails({ ...details, county: event.target.value })} />
                      <label htmlFor="county">County, state or province</label>
                    </div>
                    <div className="form-floating my-2">
                      <input type="text" className="form-control" id="postcode" autoComplete="postal-code" placeholder="SK84 8JD" required value={details.postcode} onChange={(event) => setDetails({ ...details, postcode: event.target.value })} />
                      <label htmlFor="postcode">Postcode or zip</label>
                    </div>
                    <div className="d-flex my-2">
                      <button type="submit" className="btn btn-success ms-auto">Submit</button>
                    </div>
                  </form>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};