import React, { useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';

import { MediaUpload } from "../media-upload";

export const EditableContent = ({ content, onChange, setIsLoading, controlsSlot, sectionLinksNode, backgroundColor }) => {
  const editorWrapperNode = useRef();
  const controlsSlotNode = useRef();
  const editorRef = useRef();
  const mediaUploadNode = useRef();
  const onUploadedCallback = useRef(() => {});
  const handleEditorChange = () => {
    if (editorRef.current) {
      onChange(editorRef.current.getContent());
    }
  };
  const handleUpload = (callback) => {
    onUploadedCallback.current = callback;
    mediaUploadNode.current.click();
  };
  const handleUploaded = (key) => {
    onUploadedCallback.current(key && `${window.location.protocol}//${window.location.host}/api/media/${key}`);
    onUploadedCallback.current = () => {};
  };
  const editorMinHeight = `calc(100vh - ${(controlsSlotNode.current?.clientHeight || 0) + (sectionLinksNode.current?.clientHeight || 0) + 144}px)`;

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getBody().style.backgroundColor = backgroundColor;
    }
  }, [backgroundColor]);

  return (
    <>
      <div ref={editorWrapperNode} style={{ minHeight: editorMinHeight }}>
        <MediaUpload onUploaded={handleUploaded} setUploading={setIsLoading} inputRef={mediaUploadNode} />
        {editorWrapperNode.current && controlsSlotNode.current && (
          <Editor
            apiKey="ryusucdk8035kkknnny8yggcv6zfgz9hx08b83t6hs0nthk6"
            onInit={(_, editor) => editorRef.current = editor}
            value={content}
            init={{
              relative_urls : false,
              height: editorMinHeight,
              menubar: false,
              statusbar: false,
              plugins: ['lists', 'link', 'image', `image`, `table`, `code`],
              toolbar: 'undo redo | imageButton formButton | bold italic forecolor blocks fontfamily | alignleft aligncenter alignright alignjustify | bullist numlist removeformat | link | table tableinsertdialog tablecellprops tableprops advtablerownumbering | insertGoogleMap | code',
              file_picker_types: 'image',
              file_picker_callback: (callback, _, meta) => {
                if (meta.filetype === 'image') {
                  handleUpload(callback);
                }
              },
              setup: (editor) => {
                editor.on('PreInit', () => {
                  editor.editorManager.addI18n(`en`, { "System Font": `Standard` });
                });
                editor.ui.registry.addButton('insertGoogleMap', {
                  text: `Insert Goggle map`,
                  onAction: () => {
                    const embedCode = window.prompt(`Paste in the HTML provided by google maps`, ``);

                    if (embedCode) {
                      editor.insertContent(embedCode.replace(/width=".*?"/, `width="100%"`).replace(/height=".*?"/, `height="400"`));
                    }
                  }
                });
                editor.ui.registry.addMenuButton('imageButton', {
                  text: `Insert image`,
                  fetch: (callback) => {
                    callback([
                      {
                        type: 'menuitem',
                        text: 'From your device',
                        onAction: () => {
                          handleUpload((key) => {
                            if (key) {
                              editor.insertContent(`<img src="${key}" width="256" />`);
                              editor.notificationManager.open({
                                text: `Image inserted successfully`,
                                type: `success`,
                                timeout: 4000,
                              });
                            } else {
                              editor.notificationManager.open({
                                text: `Failed to insert image, please try again.`,
                                type: `error`,
                                timeout: 4000,
                              });
                            }
                          });
                        },
                      },
                      {
                        type: 'menuitem',
                        text: `From a URL`,
                        onAction: () => editor.editorCommands.commands.exec.mceimage(),
                      },
                    ]);
                  }
                });
                editor.ui.registry.addMenuButton('formButton', {
                  text: `Forms`,
                  fetch: (callback) => {
                    callback([
                      {
                        type: 'menuitem',
                        text: 'Add form',
                        onAction: () => {
                          const formName = window.prompt(`Form name`, ``);

                          if (formName) {
                            editor.insertContent(`<p class="wysiwyg-form_item mceNonEditable">[[form-start:${formName}]]</p><br /><p class="wysiwyg-form_item mceNonEditable">[[form-end:${formName}]]</p>`);
                          }
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Add text field',
                        onAction: () => {
                          const label = window.prompt(`Text field label`, ``);

                          if (label) {
                            editor.insertContent(`<span class="wysiwyg-form_item mceNonEditable">[[text:${label}]]</span>`);
                          }
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Add checkbox',
                        onAction: () => {
                          const label = window.prompt(`Checkbox label`, ``);

                          if (label) {
                            editor.insertContent(`<span class="wysiwyg-form_item mceNonEditable">[[checkbox:${label}]]</span>`);
                          }
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Add select box',
                        onAction: () => {
                          const label = window.prompt(`Select box label`, ``);

                          if (label) {
                            let config = {
                              title: `${label} values`,
                              body: {
                                type: 'panel',
                                items: [
                                  {
                                    type: 'input',
                                    name: 'value1',
                                    inputMode: 'text',
                                    label: 'Value 1',
                                    maximized: true,
                                  },
                                  {
                                    type: 'input',
                                    name: 'value2',
                                    inputMode: 'text',
                                    label: 'Value 2',
                                    maximized: true,
                                  },
                                ]
                              },
                              buttons: [
                                {
                                  type: 'custom',
                                  text: 'Add another value',
                                  name: 'add',
                                  align: `start`,
                                },
                                {
                                  type: 'submit',
                                  text: 'Insert'
                                }
                              ],
                              onAction: (dialogApi, details) => {
                                if (details.name === 'add') {
                                  const nextNumber = parseInt(Object.keys(dialogApi.getData()).sort().pop().replace(`value`, ``)) + 1;

                                  config = { ...config, body: { ...config.body, items: [...config.body.items, {
                                    type: 'input', // component type
                                    name: `value${nextNumber}`, // identifier
                                    inputMode: 'text',
                                    label: `Value ${nextNumber}`, // text for the label
                                    maximized: true // grow width to take as much space as possible
                                  }] },
                                  initialData: dialogApi.getData(),
                                };
                                  dialogApi.redial(config);
                                  dialogApi.focus(`value${nextNumber}`);
                                }
                              },
                              onSubmit: (dialogApi) => {
                                const values = Object.values(dialogApi.getData());
                                editor.insertContent(`<span class="wysiwyg-form_item mceNonEditable">[[select:${label}:${values.join()}]]</span>`);
                                dialogApi.close();
                              }
                            };
                            editor.windowManager.open(config);
                          }
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Add Yes/No toggle',
                        onAction: () => {
                          const label = window.prompt(`Yes/No toggle label`, ``);

                          if (label) {
                            editor.insertContent(`<span class="wysiwyg-form_item mceNonEditable">[[yes-no:${label}]]</span>`);
                          }
                        }
                      },
                    ]);
                  }
                });
              },
              init_instance_callback: () => {
                editorRef.current.getBody().style.backgroundColor = backgroundColor;
                setIsLoading(false);
              },
              content_style: `body { font-size: 16px; } img { max-width: 100%; height: auto; }`,
            }}
            onEditorChange={handleEditorChange}
          />
        )}
      </div>
      <div ref={controlsSlotNode}>
        {controlsSlot}
      </div>
    </>
  );
};