import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { uid } from 'uid';
import kebabCase from "lodash/kebabCase";
import QRCode from "qrcode";

import { useEphemralState, useIsNodeInYRange } from "../hooks";
import { scroll } from "../utils";
import { Admin, EditableContent, Loading } from "../components";
import { formatContent } from "../utils";

import { StyledContent, StyledLinks, StyledLinkWrapper, StyledSection } from "./main.styles";

const keyFromName = (name) => `${kebabCase(name)}-${uid(6)}`;

const Section = ({ sectionKey, name, titleVisible, sectionNodes, index, navigate, handleManualNavigation, handleChangeTitle, canEdit, linksHeight, editing, backgroundColor, children }) => {
  useIsNodeInYRange({
    node: sectionNodes.current[index],
    rangeStart: linksHeight,
    rangeEnd: linksHeight * 2,
    handleInRange: () => navigate({ key: sectionKey }),
  });

  return (
    <StyledSection id={sectionKey} ref={(node) => (sectionNodes.current[index] = node)} light={index % 2 === 0} backgroundColor={backgroundColor}>
      <div className="d-flex align-items-center" style={{ position: `relative`, zIndex: `1` }}>
        {(canEdit || (!canEdit && titleVisible)) && (<h2 className="fs-2 mb-0"><a className="text-decoration-none link-dark" href={`#${sectionKey}`} onClick={(event) => handleManualNavigation(event, sectionKey)}>{name}</a></h2>)}
        {canEdit && (sectionKey === `admin` || !titleVisible) && (<i className="bi bi-eye-slash-fill ms-1"></i>)}
        {canEdit && sectionKey !== `admin` && !editing && (<button className="btn btn-outline-primary btn-sm mx-2" onClick={(event) => handleChangeTitle(event, name, index)}><i className="bi bi-pencil"></i></button>)}
      </div>
      {children}
    </StyledSection>
  );
}

const SectionContent = ({ editing, sectionKey, index, content, handleContentChange, handleDelete, handleCancel, handleSave, canEdit, setEditing, setIsLoading, handleManualNavigation, sectionLinksNode, handleSectionColorChange, handleTitleVisibleChange, titleVisible, backgroundColor, pageId }) => {
  const wysiwygForms = document.querySelectorAll(`.wysiwyg-form`);
  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.target);
    const id = event.target.id;

    for (let i = 0; i < event.target.elements.length; i = i + 1) {
      const element = event.target.elements[i];
      if (element.type === `checkbox`) {
        data.append(element.name, element.checked ? `Yes` : `No`);
      }
    }

    try {
      const response = await fetch(`/api/submissions/${pageId}`, { method: `POST`, body: JSON.stringify({ id, data: [...data.entries()] }) });
      if (response.status === 200) {
        window.alert(`Submission sent successfully`);
        event.target.reset();
      } else {
        window.alert(`Submission failed to send, please try again`);
      }
    } catch(_) {
      window.alert(`Submission failed to send, please try again`);
    }

    setIsLoading(false);
  }, [pageId, setIsLoading]);
  const handleEditSection = (event, key) => {
    setEditing(sectionKey);
    setIsLoading(true);

    handleManualNavigation(event, key);
  };

  useEffect(() => {
    wysiwygForms.forEach((form) => {
      if (form.getAttribute('data-has-listener') !== `true`) {
        form.addEventListener('submit', handleSubmit);
        form.setAttribute('data-has-listener', `true`);
      }
    });

    return () => {
      wysiwygForms.forEach((form) => {
        form.removeEventListener('submit', handleSubmit);
        form.removeAttribute('data-has-listener');
      });
    };
  }, [handleSubmit, wysiwygForms]);

  return (
    <StyledContent>
      {editing === sectionKey ? (
        <>
          <div className="d-flex align-items-center my-2">
            <div className="form-switch me-4">
              <input className="form-check-input me-2" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={titleVisible} onChange={(event) => handleTitleVisibleChange(event.target.checked, index)} />
              <label className="form-check-label text-nowrap" htmlFor="flexSwitchCheckChecked">Title visible</label>
            </div>
            <div className="d-flex align-items-center">
              <input type="color" className="form-control form-control-color me-2" value={backgroundColor || `#ffffff`} title="Choose your color" id="colorInput" onChange={(event) => handleSectionColorChange(event, index)} />
              <label htmlFor="colorInput" className="form-label mb-0">Background</label>
            </div>
          </div>
          <EditableContent
            content={content}
            onChange={(newContent) => handleContentChange(newContent, index)}
            setIsLoading={setIsLoading}
            controlsSlot={
              <div className="d-flex py-2">
                <button className="btn btn-outline-danger btn-sm mx-1" onClick={() => handleDelete(index)}>Delete section</button>
                <button className="btn btn-outline-warning btn-sm mx-1 ms-auto" onClick={() => handleCancel(index)}>Cancel</button>
                <button className="btn btn-outline-success btn-sm mx-1" onClick={(event) => handleSave(event, sectionKey, index)}>Save</button>
              </div>
            }
            sectionLinksNode={sectionLinksNode}
            backgroundColor={backgroundColor || `#ffffff`}
          />
        </>
      ) : (
        <>
          {canEdit && (
            <button className="btn btn-outline-primary btn-sm mt-2 mb-4"  style={{ position: `relative`, zIndex: `1` }} disabled={editing} onClick={(event) => handleEditSection(event, sectionKey)}>Edit section</button>
          )}
          <div className="my-2" dangerouslySetInnerHTML={{ __html: formatContent(content) }} />
        </>
      )}
    </StyledContent>
  );
};

const buildSections = ({ newSections, canEdit, savedSections }) => {
  const sectionsIncludingAdmin = [
    ...newSections.filter(({ key }) => key !== `admin`),
    ...(canEdit ? [{ name: `Admin`, key: `admin` }] : [])
  ];

  savedSections.current = sectionsIncludingAdmin;

  return sectionsIncludingAdmin;
};

const OffCanvas = ({ settings }) => {
  const node = useRef();

  useEffect(() => {
   return () => {
    document.querySelector(`body`).style = ``;
   };
  }, []);

  return (
    <div className="offcanvas offcanvas-start" tabIndex="-1" id="mainMenu" aria-labelledby="mainMenuLabel" ref={node}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="mainMenuLabel">Main menu</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div><a href="/" className="btn btn-outline-primary btn-sm m-2">Home</a></div>
        <div>
          {settings.isLoggedIn ? (
            <a className="btn btn-outline-warning btn-sm m-2" href="/logout">Logout</a>
          ) : (
            <a className="btn btn-outline-primary btn-sm m-2" href="/login">Login</a>
          )}
        </div>
        {!settings.hasUserSubscribed && (
          <div>
            <a className="btn btn-success btn-sm m-2" href="/join">Compose a page</a>
          </div>
        )}
        {settings.pages && settings.pages.length > 1 && (
          <div className="mt-4">
            <h2 className="fs-2">Your pages</h2>
            <ul>
              {settings.pages.map(({ id, name }) => (
                <li key={id}><a href={`/p/${id}`}>{name}</a></li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export const Main = ({ pageSettings }) => {
  const [settings, setSettings] = useState({ isLoading: true, sections: [] });
  const [editing, setEditing] = useState();
  const sectionLinksNode = useRef();
  const sectionLinksNodes = useRef([]);
  const sectionNodes = useRef([]);
  const isFirstLoad = useRef(true);
  const { pathname, hash, search } = useLocation();
  const routerNavigate = useNavigate();
  const scrollDuration = 300;
  const [isManuallyNavigating, setIsManuallyNavigating] = useEphemralState(false, scrollDuration);
  const navigate = ({ key }) => {
    if (!isManuallyNavigating) {
      routerNavigate({ hash: key, search });
    }
  };
  const handleManualNavigation = (event, key) => {
    if (event) {
      event.preventDefault();
    }

    navigate({ key });
    setIsManuallyNavigating(true);
  };
  const linksHeight = (sectionLinksNode.current?.clientHeight || 0) + 1;
  const linksHeightCssValue = `${linksHeight}px`;

  useEffect(() => {
    const key = hash.replace(`#`, ``);
    const sectionNode = sectionNodes.current.find(({ id }) => id === key);
    const activeSectionLinkNode = sectionLinksNodes.current.filter(Boolean).find(({ href }) => href.split(`#`).pop() === key);

    if (isManuallyNavigating && sectionNode) {
      scroll({
        container: window,
        direction: `y`,
        to: window.scrollY + sectionNode.getBoundingClientRect().top - sectionLinksNode.current.clientHeight,
        duration: scrollDuration,
      });
    }

    if (activeSectionLinkNode) {
      scroll({
        container: sectionLinksNode.current,
        direction: `x`,
        to: sectionLinksNode.current.scrollLeft + activeSectionLinkNode.getBoundingClientRect().left - parseInt(window.getComputedStyle(sectionLinksNode.current).getPropertyValue('padding-left')),
        duration: scrollDuration,
      });
    }
  }, [hash, isManuallyNavigating]);

  useEffect(() => {
    if (settings.sections.length && isFirstLoad.current) {
      isFirstLoad.current = false;
      setIsManuallyNavigating(true);
    }
  }, [settings, setIsManuallyNavigating]);

  const savedSections = useRef([]);

  const fetchData = useCallback(async () => {
    let tmpNotFound;
    let tmpIsLoggedIn;
    let tmpQrCodeUrl;
    let tmpHasUserSubscribed;
    let tmpIsError;
    let tmpPages;

    const sessionResponse = await fetch(`/session`);
  
    tmpIsLoggedIn = sessionResponse.status === 200

    const response = await fetch(`/api/sections/${pageSettings.pageId}`);

    let newSections = [];
    try { newSections = await response.json() } catch(_) {}

    if (response.status === 404) {
      tmpNotFound = true;
    } else if (response.status !== 200) {
      tmpIsError = true;
    }

    tmpQrCodeUrl = await QRCode.toDataURL(
      `${window.location.protocol}//${pageSettings.domain ? pageSettings.domain : `${window.location.host}${window.location.pathname}`}`,
      {
        type: `image/png`,
        color: {
          dark: '#000',
          light: '#0000'
        },
        margin: 1,
        width: 640,
      }
    );

    if (tmpIsLoggedIn) {
      const pagesResponse = await fetch(`/api/pages`);
      try { tmpPages = await pagesResponse.json() } catch(_) {}


      if (pagesResponse.status === 200) {
        tmpHasUserSubscribed = true;
      }
    }

    if ([`onepagecomposer.com`, `localhost:3000`].includes(window.location.host) && pathname === `/` && tmpPages && tmpPages.length === 1) {
      window.location.href = `/p/${tmpPages[0].id}`;
    }

    const canEdit = !search.includes('mode=preview') && tmpIsLoggedIn && tmpPages && tmpPages.some(({ id }) => id === pageSettings.pageId);

    setSettings({
      isLoading: false,
      isLoggedIn: tmpIsLoggedIn,
      qrCodeUrl: tmpQrCodeUrl,
      canEdit,
      pages: tmpPages,
      sections: buildSections({ newSections, canEdit, savedSections }),
      ...(tmpNotFound && { isNotFound: tmpNotFound }),
      ...(tmpHasUserSubscribed && { hasUserSubscribed: tmpHasUserSubscribed }),
      ...(tmpIsError && { isError: tmpIsError }),
    });
  }, [pageSettings.domain, pageSettings.pageId, pathname, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleContentChange = (newContent, index) => {
    const newSections = [...settings.sections];

    newSections[index] = { ...newSections[index], content: newContent };

    setSettings({ ...settings, isLoading: false, sections: newSections });
  };

  const handleAddSectionClick = async (event) => {
    const newHeading = window.prompt(`New section heading`, ``);

    if (!newHeading) return;

    setSettings({ ...settings, isLoading: true });

    const key = keyFromName(newHeading);

    let newSections;
  
    try {
      const response = await fetch(`/api/sections/${pageSettings.pageId}`, { method: `POST`, body: JSON.stringify({ name: newHeading, key, content: ``, titleVisible: true, }) });
      newSections = await response.json();
    } catch(_) {
      window.alert(`Unable to add a new section, please try again`);
    }

    if (newSections) {
      setSettings({ ...settings, sections: buildSections({ newSections, canEdit: settings.canEdit, savedSections }), isLoading: false });
      setTimeout(() => handleManualNavigation(event, key), 300);
    } else {
      setSettings({ ...settings, isLoading: false });
    }
  };

  const handleSave = async (event, key, index) => {
    setSettings({ ...settings, isLoading: true });

    let newSections;

    try {
      const { titleVisible, backgroundColor, content } = settings.sections[index];
      const response = await fetch(`/api/sections/${pageSettings.pageId}`, { method: `PUT`, body: JSON.stringify({ index, titleVisible, backgroundColor, content }) });
      newSections = await response.json();
    } catch(_) {
      window.alert(`Unable to save section, please try again`);
    }

    if (newSections) {
      setSettings({ ...settings, sections: buildSections({ newSections, canEdit: settings.canEdit, savedSections }) });
      setTimeout(() => handleManualNavigation(event, key), 300);
    } else {
      setSettings({ ...settings, isLoading: false });
    }

    setEditing();
  };

  const handleDelete = async (index) => {
    if (!window.confirm(`Are you sure you would like to delete this section?`)) return;

    setSettings({ ...settings, isLoading: true });

    let newSections;

    try {
      const response = await fetch(`/api/sections/${pageSettings.pageId}`, { method: `DELETE`, body: JSON.stringify({ index }) });
      newSections = await response.json();
    } catch(_) {
      window.alert(`Unable to delete section, please try again`);
    }

    if (newSections) {
      setSettings({ ...settings, sections: buildSections({ newSections, canEdit: settings.canEdit, savedSections, isLoading: false }) });
    } else {
      setSettings({ ...settings, isLoading: false });
    }
    setEditing();
  };

  const handleChangeTitle = async (event, name, index) => {
    const newHeading = window.prompt('Change section heading', name);

    if (!newHeading) return;

    setSettings({ ...settings, isLoading: true });

    const newkey = keyFromName(newHeading);

    let newSections;

    try {
      const response = await fetch(`/api/sections/${pageSettings.pageId}`, { method: `PUT`, body: JSON.stringify({ index, name: newHeading, key: newkey }) });
      newSections = await response.json();
    } catch {
      window.alert(`Unable to change section title, please try again`);
    }

    if (newSections) {
      setSettings({ ...settings, sections: buildSections({ newSections, canEdit: settings.canEdit, savedSections }) });
      setTimeout(() => handleManualNavigation(event, newkey), 300);
    } else {
      setSettings({ ...settings, isLoading: false });
    }
  };

  const handleCancel = (index) => {
    if (JSON.stringify(savedSections.current) !== JSON.stringify(settings.sections)) {
      if (!window.confirm(`Any unsaved changes you've made to this section will be lost. Are you sure?`)) return;
    }

    const newSections = [...settings.sections];

    newSections[index] = { ...savedSections.current[index] };

    setSettings({ ...settings, sections: newSections });
    setEditing();
  };

  const handleSectionColorChange  = (event, index) => {
    const newSections = [...settings.sections];

    newSections[index] = { ...newSections[index], backgroundColor: event.target.value };

    setSettings({ ...settings, sections: newSections });
  };


  const handleTitleVisibleChange  = (titleVisible, index) => {
    const newSections = [...settings.sections];

    newSections[index] = { ...newSections[index], titleVisible };

    if (!newSections.some(({ titleVisible }) => titleVisible)) {
      alert(`You must have at least one section title visible`);

      return;
    }

    setSettings({ ...settings, sections: newSections });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (JSON.stringify(savedSections.current) !== JSON.stringify(settings.sections)) {
        event.returnValue = `Any unsaved changes you've made to this section will be lost. Are you sure?`;
      }
    };

    window.addEventListener(`beforeunload`, handleBeforeUnload);

    return () => {
      window.removeEventListener(`beforeunload`, handleBeforeUnload);
    }
  }, [settings.sections]);

  if (settings.isNotFound) {
    return <div className="m-2">Page not found</div>;
  }

  if (settings.isError) {
    return <div className="m-2">Page errored. Please try again.</div>;
  }

  return (
    <>
      {Boolean(settings.sections.length) && (
        <div style={{ paddingBottom: `calc(100vh - ${sectionNodes.current[sectionNodes.current.length - 1]?.clientHeight + (linksHeight * 2)}px)` }}>
          <StyledLinks ref={sectionLinksNode} className="bg-white">
            {settings.sections.filter(({ titleVisible }) => settings.canEdit || (!settings.canEdit && titleVisible)).map(({ name, key, titleVisible }, index) => (
              <StyledLinkWrapper key={`navigation_${key}`}>
                <a href={`#${key}`} className={`btn btn-sm text-nowrap ${key === `admin` ? `btn-outline-secondary` : `btn-outline-dark`}${hash.replace(`#`, ``) === key ? ` active` : ``}`} role="button" aria-pressed="true" ref={(node) => (sectionLinksNodes.current[index] = node)} onClick={(event) => handleManualNavigation(event, key)}>
                  {name}
                  {settings.canEdit && (key === `admin` || !titleVisible) && (<i className="bi bi-eye-slash-fill ms-1"></i>)}
                </a>
              </StyledLinkWrapper>
            ))}
          </StyledLinks>
          <div className="bg-light" style={{ marginTop: linksHeightCssValue, marginBottom: linksHeightCssValue }}>
            <div className="d-flex bg-light" style={{ borderBottom: `1px solid #ececec` }}>
              <button className="btn btn-sm btn-outline-dark m-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#mainMenu" aria-controls="mainMenu">
                <i className="bi bi-list"></i>
              </button>
              <button
                className="btn btn-sm btn-outline-dark m-2 ms-auto"
                type="button"
                onClick={() => {
                  const url = `${window.location.protocol}//${pageSettings.domain ? pageSettings.domain : `${window.location.host}${window.location.pathname}`}`;

                  if (navigator.share) {
                    navigator.share({ url });
                  } else {
                    navigator.clipboard.writeText(url);
                    setTimeout(() => alert(`URL copied to clipboard`), 300);
                  }
                }}
              >
                <i className="bi bi-share"></i>
              </button>
            </div>
            {settings.sections.map(({ key: sectionKey, name, content, backgroundColor, titleVisible }, index) => (
              <Section
                key={`section_${sectionKey}`}
                sectionKey={sectionKey}
                name={name}
                sectionNodes={sectionNodes}
                index={index}
                navigate={navigate}
                handleManualNavigation={handleManualNavigation}
                handleChangeTitle={handleChangeTitle}
                canEdit={settings.canEdit}
                linksHeight={linksHeight}
                editing={editing}
                backgroundColor={backgroundColor}
                titleVisible={titleVisible}
              >
                {
                  sectionKey === `admin` && settings.canEdit ? (
                    <Admin
                      settings={settings}
                      editing={editing}
                      handleAddSectionClick={handleAddSectionClick}
                      pageSettings={pageSettings}
                      setIsLoading={(isLoading) => {
                        setSettings({ ...settings, isLoading })
                      }}
                    />
                  ) : (
                    <SectionContent
                      editing={editing}
                      sectionKey={sectionKey}
                      index={index}
                      content={content}
                      handleContentChange={handleContentChange}
                      handleDelete={handleDelete}
                      handleCancel={handleCancel}
                      handleSave={handleSave}
                      canEdit={settings.canEdit}
                      setEditing={setEditing}
                      setIsLoading={(isLoading) => {
                        setSettings({ ...settings, isLoading })
                      }}
                      handleManualNavigation={handleManualNavigation}
                      sectionLinksNode={sectionLinksNode}
                      handleSectionColorChange={handleSectionColorChange}
                      backgroundColor={backgroundColor}
                      handleTitleVisibleChange={handleTitleVisibleChange}
                      titleVisible={titleVisible}
                      pageId={pageSettings.pageId}
                    />
                  )
                }
              </Section>
            ))}
          </div>
          <OffCanvas settings={settings} />
        </div>
      )}
      {settings.isLoading && <Loading />}
    </>
  );
};