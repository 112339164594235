import { useCallback, useEffect, useRef, useState } from "react";

export const useIsNodeInYRange = ({ node, rangeStart, rangeEnd, handleInRange }) => {
  const animationFrameId = useRef();
  const [isInRange, setTmpIsInRange] = useState(false);
  const [previousIsInRange, setPreviousIsInRange] = useState(false);
  const previousTimestamp = useRef(0);
  const step = useCallback((timestamp) => {
    if (timestamp - previousTimestamp.current > 20) {
      previousTimestamp.current = timestamp;
      const top = node?.getBoundingClientRect().top;

      node && setTmpIsInRange(top > rangeStart && top < rangeEnd);
    }

    animationFrameId.current = window.requestAnimationFrame(step);
  }, [node, rangeEnd, rangeStart]);

  useEffect(() => {
    animationFrameId.current = window.requestAnimationFrame(step);

    return () =>  {
      previousTimestamp.current = 0;
      window.cancelAnimationFrame(animationFrameId.current)
    };
  }, [step]);

  useEffect(() => {
    if (isInRange !== previousIsInRange) {
      setPreviousIsInRange(isInRange);
      handleInRange();
    }
  }, [isInRange, previousIsInRange, handleInRange]);
};