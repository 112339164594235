import styled from "styled-components";


export const StyledLinks = styled.ul`
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  display: flex;
  margin: 0;
  list-style-type: none;
  min-height: 2rem;
  overflow: auto;
  padding: 1rem 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const StyledLinkWrapper = styled.li`
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
    padding-right: 0.25rem;
  }
`;

export const StyledSection = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : `#fcfcfc`};
  padding: 1rem;
  position: relative;

  ${({ light, backgroundColor }) => !backgroundColor && light && `
    background: none;
  `}}
`;

export const StyledContent = styled.div`
  & textarea {
    display: none;
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  & table {
    margin-bottom: 1rem;
  }

  & .wysiwyg-form {
    max-width: 44rem;
  }

  & .wysiwyg-form p {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  & .wysiwyg-form .form-check-label {
    white-space: nowrap;
  }

  & .wysiwyg-form button[type="submit"] {
    margin-left: auto;
  }

  & .wysiwyg-form .form-floating {
    display: block;
    width: 100%;
  }

  & .wysiwyg-form .wysiwyg-form_item {
    width: 100%;
  }

  & .experimental-background-video {
    display: block;
    margin: -1.5rem -1rem;
    min-height: 56.25vw;
    pointer-events: none;
    width: calc(100% + 2rem);
  }
`;